<template>
  <div>
    <h1 class="font-weight-light text-center m-5">{{ homeScreenText }}</h1>
    <div class="project">
      <ProjectListItem :project="project" v-for="project in projects" :key="project.id"/>
    </div>
  </div>
</template>

<script>
import ProjectListItem from "@/components/ProjectListItem";
export default {
  name: "project",
  components: {
    ProjectListItem
  },
  props: ["projects", "loggedIn"],
  data() {
    return {};
  },
  mounted() {
    if (!this.loggedIn) this.$router.push("login");
    this.$emit("updateProjId", -1);
  },
  computed: {
    homeScreenText() {
      return this.projects.length > 0
        ? "Select Project From List Below"
        : "You are not attached to any BMP projects";
    }
  }
};
</script>

<style lang="scss" scoped>
.project {
  // padding: 40px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
</style>
