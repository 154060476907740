<template>
  <div class="project-list-item">
    <b-card :name="name" class="m-2 texture">
      <img
        v-if="showImage"
        class="mb-2 card-img-top"
        :src="projectLogo"
        :alt="name+' logo'"
        @error="handleError"
      />
      <h4>{{name}}</h4>
      <p class="card-text">{{street}}</p>
      <p class="card-text">{{city}}, {{state}}</p>
      <p class="card-text">{{country}} {{zip}}</p>
      <router-link :to="'/'+project.slug + '/contacts'">
        <b-button class="mt-2" size="sm" variant="primary">View Project</b-button>
      </router-link>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      name: this.project.name,
      street: this.project.location_street,
      city: this.project.location_city,
      state: this.project.location_state,
      country: this.project.location_country,
      zip: this.project.location_zip,
      projectLogo:
        process.env.VUE_APP_ROOT_API +
        "barrier-management/project/" +
        this.project.id +
        "/logo",
      showImage: true
    };
  },
  methods: {
    handleError() {
      this.showImage = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.project-list-item {
  width: 300px;
}
p {
  margin: 0;
  line-height: 1.6em;
}
</style>

